import React, { useEffect, useRef } from 'react';
import './App.css';
import imageSrc from './Me.png'; // Replace with your image path or URL

function App() {
  const spinnerRef = useRef(null);

  useEffect(() => {
    // Initial normal spin animation that keeps the image centered
    const normalAnimation = 'normal-spin 5s linear infinite';
    // Array of combined silly animations with movement
    const sillyAnimations = [
      'wobble-move 4s infinite alternate',
      'bounce-move 4s infinite alternate',
      'zigzag-move 4s infinite alternate',
      'crazy-move 4s infinite alternate',
    ];

    // Set the normal spin animation first
    if (spinnerRef.current) {
      spinnerRef.current.style.animation = normalAnimation;
    }

    // After 15 seconds, switch to a random silly animation with movement
    const timeoutId = setTimeout(() => {
      const randomAnimation =
        sillyAnimations[Math.floor(Math.random() * sillyAnimations.length)];
      if (spinnerRef.current) {
        console.log(randomAnimation);
        spinnerRef.current.style.animation = randomAnimation;
      }
    }, 15000); // 15000 milliseconds = 15 seconds

    // Cleanup timeout if the component unmounts
    return () => clearTimeout(timeoutId);
  }, []);

  return (
    <div className="App">
      <header className="App-header">
        <img ref={spinnerRef} src={imageSrc} className="spinner" alt="Spinning" />
      </header>
    </div>
  );
}

export default App;
